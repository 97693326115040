import React, { useState } from 'react';
import { FaGithub, FaLinkedinIn, FaEnvelope } from 'react-icons/fa';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    _gotcha: '' // Honeypot for spam protection
  });
  const [loading, setLoading] = useState(false); // Loading state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    try {
      const response = await fetch('https://getform.io/f/wbrkpeka', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      setLoading(false); // Stop loading on response
      if (response.ok) {
        alert('Message sent successfully!');
        setFormData({ name: '', email: '', message: '', _gotcha: '' }); // Clear form data
      } else {
        alert('Failed to send message. Please try again.');
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
      setLoading(false); // Stop loading on error
    }
  };

  return (
    <div className="contact-section py-16 text-white relative">
      <div className="container mx-auto px-4 relative z-10">
        <h2 id="Contact" className="text-2xl font-abc font-medium gradient-text mb-10">03. Contact</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          <div>
            <h3 className="text-xl mb-5">Let's Connect</h3>
            <p>Feel free to reach out for collaborations or just a friendly hello 👋</p>
            <div className="social-links flex space-x-4 mt-6">
              <a href="https://github.com/iTzAlqxander" target="_blank" rel="noopener noreferrer" className="hover:text-[#6fddf8] transition duration-300"><FaGithub size={28} /></a>
              <a href="https://www.linkedin.com/in/alexander-masztak/" target="_blank" rel="noopener noreferrer" className="hover:text-[#6fddf8] transition duration-300"><FaLinkedinIn size={28} /></a>
              <a href="mailto:alexander@masztak.com" className="hover:text-[#6fddf8] transition duration-300"><FaEnvelope size={28} /></a>
            </div>
          </div>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Your Name"
              className="w-full p-3 bg-gray-800 rounded focus:outline-none focus:ring-2 focus:ring-[#007CF0] transition duration-300"
              required
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Your Email"
              className="w-full p-3 bg-gray-800 rounded focus:outline-none focus:ring-2 focus:ring-[#007CF0] transition duration-300"
              required
            />
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Your Message"
              className="w-full p-3 bg-gray-800 rounded focus:outline-none focus:ring-2 focus:ring-[#007CF0] transition duration-300 h-40"
              required
              style={{ resize: 'vertical', maxHeight: '300px' }}
            ></textarea>
            <input
              type="hidden"
              name="_gotcha"
              style={{ display: 'none' }}
              value={formData._gotcha}
              onChange={handleChange}
            />
            <button type="submit" disabled={loading} className="px-6 py-3 rounded text-black bg-gradient-to-r from-[#6fddf8] to-[#007CF0] hover:from-[#5bc6ff] hover:to-[#4da7db] transition duration-300">
              {loading ? 'Sending...' : 'Send Message'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
